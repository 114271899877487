<template>
  <div>
    <div class="flex-align" style="gap:8px">
      <input
          class="input"
          ref="input"
          type="text"
          v-model="inputValue"
          :maxlength="13"
          @keyup="onKeyup"
          @keyup.enter="clickValid"
          placeholder="휴대전화번호 입력"
          :disabled="disabled"/>
        
        <button v-if="enableUpdate" 
                class="button" @click="clickUpdate">변경</button>
        <button v-else-if="!valid" :disabled="inputDisabled" 
                class="button" @click="clickValid">인증</button>
        <button v-else :disabled="success"
                class="button" @click="clickResend">재전송</button>
    </div>
    <div class="msg-error" v-if="error">
      <img src="/static/icon/ic_warning_circle_error.svg">
      <div class="size-12 highlight">{{ errorMsg }}</div>
    </div>
    <div v-if="valid" class="flex-align" style="margin-top:8px;gap: 8px">
      <div class="position-relative valid">
        <input maxlength="6" placeholder="인증번호 6자리" type="text" v-model="validNum"
               class="input" :class="{'success': success, 'error': errorValid}" :readonly="success">
            <div class="remain highlight" v-if="remainNum > -1">{{ remain }}</div>
      </div>
      <button class="button is-primary" :disabled="success" @click="confirm">확인</button>
    </div>
    <div class="msg-success" v-if="success">
      <i class="material-icons">check</i>
      <div class="size-12">인증이 완료되었습니다.</div>
    </div>
    <div class="msg-error" v-else-if="errorValid">
      <img src="/static/icon/ic_exclamation.svg">
      <div class="size-12 highlight">{{ errorValidMsg }}</div>
    </div>
    <div class="msg">* {{ label }}로 알림 SMS, 카카오알림톡이 전송됩니다.</div>
  </div>
</template>
<script>
  import {numToTime} from '@/utils/helper/format-helper'
  import FormMixin from "@/mixins/FormMixin";
  export default {
    name: "InputPhoneVerify",
    mixins: [
      FormMixin
    ],
    props: {
      value: {
        type: String
      },
      disabled: {
        type: Boolean,
        required: false
      },
      placeholder: {
        type: String,
        default: ''
      },
      validate: {
        type: Object
      },
      label: {
        type: String,
        default: '휴대전화번호'
      }
    },
    data() {
      return {
        inputValue: '',
        validNum: '',
        valid: false,
        success: false,
        error: false,
        errorMsg: '필수입력 사항입니다.',
        errorValid: false,
        errorValidMsg: '필수입력 사항입니다.',
        remainNum: -1,
        itv: undefined,
        inputDisabled: false,
        enableUpdate: false
      }
    },
    computed: {
      remain() {
        return numToTime(this.remainNum).substring(2);
      }
    },
    created() {
      if(this.$route.path.includes('partner_info')) {
        if(this.value) {
          this.inputValue = this.value;
          this.enableUpdate = true;
        }
      }
    },
    methods: {
      onKeyup(e) {
        this.inputValue = this.autoHyphenPhone(e.target.value);
      },
      clickUpdate() {
        this.enableUpdate = false;
        this.$emit('update:disabled', false);
        this.inputValue = '';
      },
      clickValid() {
         // 인증 관련 로직 실행
         if(this.inputValue === '') {
          this.error = true;
          this.errorMsg = '필수입력 사항입니다.';
        } else {
          this.$store.commit('setLoading', true);
          this.$axios.post('public/phone/verify/send', {phone: this.inputValue}).then(() => {
            this.$store.commit('setLoading', false);
            this.toast('인증번호가 발송되었습니다.');
            this.inputDisabled = true;
            this.error = false;
            this.valid = true;
            this.remainNum = 300;
            this.itv = setInterval(this.intervalRemain, 1000);
          }).catch(() => {
            this.$store.commit('setLoading', false);
            this.toast('인증번호 발송에 실패했습니다.');
          });
        }
      },
      clickResend() {
        if (!this.success) {
          this.$store.commit('setLoading', true);
          this.$axios.post('public/phone/verify/send', {phone: this.inputValue}).then(() => {
            this.$store.commit('setLoading', false);
            this.toast('인증번호가 재발송되었습니다.');
            clearInterval(this.itv);
            this.inputDisabled = true;
            this.error = false;
            this.valid = true;
            this.remainNum = 300;
            this.itv = setInterval(this.intervalRemain, 1000);
          }).catch(() => {
            this.$store.commit('setLoading', false);
            this.toast('인증번호 발송에 실패했습니다.');
          })
        }
      },
      confirm() {
        let params = {
          phone: this.inputValue,
          verify_num: this.validNum
        }
        this.$axios.post('public/phone/verify/confirm', params).then(() => {
          if (this.remainNum > 0) {
            this.errorValid = false;
            this.success = true;
            this.validate.success = true;
            this.remainNum = -1;
            clearInterval(this.itv);
            this.$emit('update:value', this.inputValue);
            this.$emit('update:validate', this.validate);
            this.$emit('auth',this.inputValue);
          } else {
            this.errorValidMsg = '인증번호가 만료되었습니다.';
            this.errorValid = true;
            this.inputDisabled = false;
          }
        }).catch(() => {
          this.toast('인증번호가 일치하지 않습니다.');
          this.errorValidMsg = '인증번호가 일치하지 않습니다.';
          this.errorValid = true;
        })
      },
      intervalRemain() {
        this.remainNum--;
        if(this.remainNum===0) {
          clearInterval(this.itv);
          this.errorValidMsg = '인증번호가 만료되었습니다.';
          this.errorValid = true;
          this.inputDisabled = false;
        }
      },
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  input
    max-width 240px
    height 48px
  .valid
    max-width 240px
    flex 1
  .error
    border 1px solid $error !important

  .success
    border 1px solid $success !important

  .msg
    margin-top 8px
    font-size 14px
    color $sub2

  .button
    width 80px
    height 48px
    font-size 16px
  .msg-error
  .msg-success
    display flex
    align-items center
    margin-top 8px
    gap 0 4px

  .msg-success
    color $success
    i
      font-size 12px

  .highlight
    color #FF3B3B

  .remain
    position absolute
    top calc(50% - 12px)
    right 20px
    font-size 15px

  .button:disabled
    background white !important
    border 1px solid $sub5 !important
    cursor default
    color $sub5 !important
  .button:disabled:hover
    background white !important
    border 1px solid $sub5 !important
    color $sub5 !important

  @media (max-width: 768px)
    input
    .valid
      max-width 100%
      flex 1
</style>
